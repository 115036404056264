.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color:  white ;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.valid-offer-container,
.invalid-offer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 195px;
  padding-bottom: 240px;
}

.valid-offer-container img,
.invalid-offer-container img {
  max-width: 40%;
  height: auto;
  margin-bottom: 20px;
}

.valid-offer-container p,
.invalid-offer-container p {
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 20px;
  font-family: Montserrat ;
  text-align: center;
}

.valid-offer-container span,
.invalid-offer-container span {
  font-size: 16px;
  font-weight: normal;
  font-family: Montserrat ;
  text-align: center;
}

.custom-button {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border-radius: 21px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
  width: 200px;
  padding: 13px;
  text-align: center;
  display: inline-block;
  font-family: Montserrat;
  font-size: 16px;
}

.custom-button:hover {
  background-color: #333;
}





.privilege-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
}

.privilege-section .title-container {
  width: 100%;
  margin: 64px 48px;
  color: #1A1A1A;
}

.title-container .main-title {
  font-size: 55px;
  font-weight: 500;
}

.title-container .description {
  font-size: 18px;
  font-weight: 400;
  color: #424242;
}

.grid-container {
  display: grid;
  gap: 32px;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  justify-items: center;
  max-width: 100%;
}

@media screen and (max-width: 627px) {
  .title-container .main-title {
    font-size: 27px;
  }
}

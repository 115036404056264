.card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    border-radius: 40px;
    width: 288px;
    height: 379px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
}

.card::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 40px;
    z-index: 1;
}

.card-content {
    position: relative;
    margin: 32px 30px;
    text-align: start;
    z-index: 2;
}

.card-title {
    color: white;
    font-size: 20px;
    font-weight: 600;
}

.link {
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    background-color: transparent;
    border: 0;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 400;
}

@media screen and (max-width: 627px) {
    .card {
        width: 315px;
        height: 235px;
    }
}
/* Not found container styling */
.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
  color: #333;
  text-align: center;
}

/* Error code styling */
.error-code {
  font-size: 100px;
  color: #e74c3c;
  margin-bottom: 10px;
}

/* Error message styling */
.error-message {
  font-size: 18px;
  margin-bottom: 20px;
}

/* Redirect message styling */
.redirect-message {
  font-size: 16px;
  margin-bottom: 20px;
}

/* Link styling */
.home-link {
  text-decoration: none;
  color: #3498db;
  font-size: 16px;
  font-weight: bold;
  border: 2px solid #3498db;
  padding: 10px 20px;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.home-link:hover {
  background-color: #3498db;
  color: #fff;
}

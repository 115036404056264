.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
}

.secondary-header {
    width: 85%;
    margin-top: 64px;
    height: 250px;
    border-radius: 27px;
    background-image: url('../../../public/welcome-premium-club.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
}

.secondary-header-title {
    color: white;
    font-size: 50px;
    font-weight: 600;
    max-width: 70%;
}

@media screen and (max-width: 627px) {
    .secondary-header-title {
        font-size: 25px;
    }
}
